import { NgTemplateOutlet, SlicePipe } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, Component, contentChildren, input, output, signal, TemplateRef } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

import { ReversePipe } from '@@shared/reverse-pipe';

import { SlTemplateDirective } from '../../directives/sl-template.directive';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
	selector: 'sl-avatars',
	templateUrl: './avatars.component.html',
	styleUrl: './avatars.component.scss',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		SlicePipe,
		AvatarComponent,
		ReversePipe,
		SlTemplateDirective,
		NgTemplateOutlet,
		TooltipModule,
	]
})
export class AvatarsComponent implements AfterContentInit {
	readonly avatarsSignal$ = input<any[]>(null, { alias: 'avatars' });
	readonly sizeSignal$ = input<number>(24, { alias: 'size' });
	readonly propToHashSignal$ = input<string>(null, { alias: 'propToHash' });
	readonly propToDisplaySignal$ = input<string>(null, { alias: 'propToDisplay' });
	readonly avatarsToDisplaySignal$ = input<number>(5, { alias: 'avatarsToDisplay' });
	readonly displayModeSignal$ = input<'ltr' | 'rtl'>('rtl', { alias: 'displayMode' });

	readonly moreAvatarsEnter = output<{ event: MouseEvent; avatars: any }>();
	readonly moreAvatarsLeave = output<MouseEvent>();
	readonly avatarsEnter = output<{ event: MouseEvent; avatar: any }>();
	readonly avatarsLeave = output<{ event: MouseEvent; avatar: any }>();
	readonly avatarEnter = output<{ event: MouseEvent; avatar: any }>();
	readonly avatarLeave = output<{ event: MouseEvent; avatar: any }>();

	readonly templates = contentChildren(SlTemplateDirective);

	readonly hoveredAvatarsSignal$ = signal<any | any[]>(null);
	avatarTooltipTemplate: TemplateRef<any>;
	moreAvatarsTooltipTemplate: TemplateRef<any>;

	ngAfterContentInit(): void {
		this.#assignContentToLocalTemplate();
	}

	onAvatarMouseEnter(event: MouseEvent, avatar: any): void {
		this.hoveredAvatarsSignal$.set(avatar);
		this.avatarEnter.emit({ event, avatar });
	}

	onAvatarMouseLeave(event: MouseEvent, avatar: any): void {
		this.hoveredAvatarsSignal$.set(null);
		this.avatarLeave.emit({ event, avatar });
	}

	onMoreAvatarMouseEnter(event: MouseEvent): void {
		const avatars = this.avatarsSignal$()?.slice((this.avatarsSignal$().length > this.avatarsToDisplaySignal$()) ? this.avatarsToDisplaySignal$() - 1 : this.avatarsToDisplaySignal$());
		this.hoveredAvatarsSignal$.set(avatars);
		this.moreAvatarsEnter.emit({ event, avatars });
	}
	onMoreAvatarMouseLeave(event: MouseEvent): void {
		this.hoveredAvatarsSignal$.set(null);
		this.moreAvatarsLeave.emit(event);
	}

	#assignContentToLocalTemplate(): void {
		this.templates()?.forEach((item: SlTemplateDirective) => {
			switch (item.getType()) {
				case 'avatarTooltip':
					this.avatarTooltipTemplate = item.template;
					break;

				case 'moreAvatarsTooltip':
					this.moreAvatarsTooltipTemplate = item.template;
					break;
			}
		});
	}
}
