@if (avatarsSignal$()?.length) {
	<div class="avatar-group sl-flexbox-row sl-flexbox-align-center {{ displayModeSignal$() }}">
		@if (avatarsSignal$()?.length > avatarsToDisplaySignal$()) {
			<sl-avatar 	[rawText]="'+' + (avatarsSignal$().length - (avatarsToDisplaySignal$() - 1))"
						styleClass="more-avatar"
						[size]="sizeSignal$()"
						[pTooltip]="moreAvatarsTooltipTemplate && hoveredAvatarsSignal$()?.length ? moreAvatarsTooltip : null"
						tooltipPosition="top"
						tooltipStyleClass="sl-v1-tooltip"
						(mouseenter)="onMoreAvatarMouseEnter($event)"
						(mouseleave)="onMoreAvatarMouseLeave($event)">
			</sl-avatar>
		}
		@for (avatar of (avatarsSignal$() | slice:0:((avatarsSignal$().length > avatarsToDisplaySignal$()) ? avatarsToDisplaySignal$() - 1 : avatarsToDisplaySignal$())) | reverse; track avatar; let i = $index;) {
			<sl-avatar 	[value]="avatar"
						[size]="sizeSignal$()"
						[propToHash]="propToHashSignal$()"
						[propToDisplay]="propToDisplaySignal$()"
						[pTooltip]="avatarTooltipTemplate && hoveredAvatarsSignal$() ? avatarTooltip : null"
						tooltipPosition="top"
						tooltipStyleClass="sl-v1-tooltip"
						(mouseenter)="onAvatarMouseEnter($event, avatar)"
						(mouseleave)="onAvatarMouseLeave($event, avatar)">
			</sl-avatar>
		}
	</div>
}
<ng-template #avatarTooltip>
	<ng-container *ngIf="avatarTooltipTemplate" [ngTemplateOutlet]="avatarTooltipTemplate" [ngTemplateOutletContext]="{ $implicit: hoveredAvatarsSignal$() }"></ng-container>
</ng-template>
<ng-template #moreAvatarsTooltip>
	<ng-container *ngIf="moreAvatarsTooltipTemplate" [ngTemplateOutlet]="moreAvatarsTooltipTemplate" [ngTemplateOutletContext]="{ $implicit: hoveredAvatarsSignal$() }"></ng-container>
</ng-template>
