import { Directive, inject, input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[slTemplate]',
	standalone: true,
})
export class SlTemplateDirective {
	readonly templateNameSignal$ = input<string>(null, { alias: 'slTemplate' });

	readonly template = inject(TemplateRef<any>);

	getType(): string {
		return this.templateNameSignal$();
	}
}
